import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-blue-dark flex justify-center w-full" }
const _hoisted_2 = { class: "xl:flex flex-row-reverse container mx-auto w-full items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerseLinks = _resolveComponent("VerseLinks")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_VerseSelect = _resolveComponent("VerseSelect")!
  const _component_MobileNav = _resolveComponent("MobileNav")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VerseLinks, {
        mobile: false,
        class: "text-grey-light hidden md:flex w-full xl:justify-end justify-end h-12"
      }),
      _createVNode(_component_MenuIcon, {
        class: "md:hidden cursor-pointer h-16 ml-auto text-grey-light",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mobileMenu = true)),
        verseLinks: true
      }),
      _createVNode(_component_VerseSelect, { class: "self-center pl-2 text-grey-light" })
    ]),
    (_ctx.mobileMenu)
      ? (_openBlock(), _createBlock(_component_MobileNav, {
          key: 0,
          verseLinks: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mobileMenu = false)),
          class: "bg-green-800"
        }))
      : _createCommentVNode("", true)
  ]))
}