
import { computed, defineComponent, watch, onMounted } from 'vue';
import { useMeta } from 'vue-meta';
import Header from '@/components/global/Header.vue';
import VerseNavBar from '@/components/verse_navigation/VerseNavBar.vue';
import Footer from '@/components/global/Footer.vue';
import ErrorModal from '@/components/global/ErrorModal.vue';
import { useRoute } from 'vue-router';
import { clearErrors, errors } from './api/base';

declare global {
  interface Window {
    goatcounter?: {
      count: (opts?: { path?: string; title?: string; event?: string; referrer?: string }) => void;
    };
  }
}

const trackPageView = () => {
  const t = setInterval(() => {
    if (!window || !window.goatcounter || !window.goatcounter.count) {
      return;
    }
    clearInterval(t);

    window.goatcounter.count({
      path: window.location.pathname + window.location.search + window.location.hash,
    });
  }, 100);
};

export default defineComponent({
  components: {
    Header,
    Footer,
    VerseNavBar,
    ErrorModal,
  },
  setup() {
    const route = useRoute();
    const meta = computed(() => ({
      htmlAttrs: { lang: route.params.lang ?? '' },
      description: 'Corpus Coranicum',
      link: [
        {
          rel: 'canonical',
          href: `https://corpuscoranicum.org/${route.params.lang}`,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: 'https://corpuscoranicum.org/de',
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: 'https://corpuscoranicum.org/en',
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: 'https://corpuscoranicum.org/fr',
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: 'https://corpuscoranicum.org/de',
        },
      ],
    }));
    useMeta(meta);

    function checkNotFound() {
      const robotsTag = document.querySelector("meta[name='robots']");
      if (robotsTag) {
        robotsTag.remove();
      }
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      if (route.name === 'NotFound' || route.name === 'LanguageNotFound') {
        metaRobots.content = 'noindex, nofollow';
      } else {
        metaRobots.content = 'index, follow';
      }
      document.head.appendChild(metaRobots);
    }
    checkNotFound();

    onMounted(() => {
      trackPageView();
    });

    watch(route, () => {
      trackPageView();
      checkNotFound();
    });

    return {
      errors,
      clearErrors,
    };
  },
});
